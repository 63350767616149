<template>
	<div class='pb16 pt10'>
		<div class="w-max bg-f color-f pb30">
			<div class="fs11 mt4 text-center mr50 ml50 color9D">铭智生科经过多年优秀的运营，凭借着业内良好的口碑，收获了一批批优质客户，以下为一些参考性案例。</div>
			<!-- 轮播图 -->
			<van-swipe :autoplay="3000" indicator-color='#F7B500' class="mt16">
				<van-swipe-item v-for="(image, index) in list" :key="index">
					<img v-lazy="image.wapPic" class="w343 h415" style="margin: 0 auto;" />
					<!-- <div class="w343 h388 flex row-center col-center" style="background: #D8D8D8;margin: 0 auto;">
					</div> -->
				</van-swipe-item>
			</van-swipe>
			<!-- //轮播图 -->
		</div>
		<div class="mt30">
			<div class="fs11 text-center mr50 ml50 color9D mb20">大型合作企业展示</div>
			<div class="pl16 flex flex-wrap">
				<img :src="item.img" class="w105 h72 mr14 mb14" style="object-fit: cover;" v-for="(item,i) in imgList" :key="i"></img>
			</div>
		</div>
	</div>
</template>

<script>
	import { getCase } from '@/api'
	export default {
		data() {
			return {
				list: [],
				imgList: [{
						img: require('@/assets/images/partner/12306.webp')
					}, {
				 	img: require('@/assets/images/partner/beijing.webp')
					}, {
						img: require('@/assets/images/partner/chongqing.webp')
					}, {
						img: require('@/assets/images/partner/guoneng.webp')
					}, {
						img: require('@/assets/images/partner/guoyao.webp')
					}, {
				  img: require('@/assets/images/partner/lepinhui.webp')
					}, {
						img: require('@/assets/images/partner/lepu.webp')
					}, {
						img: require('@/assets/images/partner/luhe.webp')
					}, {
						img: require('@/assets/images/partner/nongyedaxue.webp')
					}, 
					{
						img: require('@/assets/images/partner/quanjude.webp')
					}, {
				  img: require('@/assets/images/partner/red.webp')
					}, {
						img: require('@/assets/images/partner/shoulv.webp')
					},
					{
						img: require('@/assets/images/partner/yidong.webp')
					},
					{
						img: require('@/assets/images/partner/yuanxin.webp')
					}, {
						img: require('@/assets/images/partner/zhejiangyiliao.webp')
					},
					// {
					// img: require '@/assets/images/partner/yidong.webp'
					// }, {
					// img: require '@/assets/images/partner/zhejiangyiliao.webp'
					// }
				]
			};
		},

		components: {},

		created() {
			this.$store.commit('editNavUrl', this.$route.path)
			this.getCase()
		},

		mounted() {},

		methods: {
			async getCase() {
				const res = await getCase()
				this.list = res.data
			}
		}
	}
</script>
<style lang='scss' scoped>
</style>
